export enum Spec {
  AppControllerLayoutFix = 'specs.siteSearch.AppControllerLayoutFix',
  NewResultsPerPageDefaultValue = 'specs.siteSearch.NewResultsPerPageDefaultValue',
  ProGallery = 'specs.siteSearch.ProGallery',
  UseWarmupData = 'specs.siteSearch.UseWarmupData',
  MobileSettings = 'specs.siteSearch.MobileSettings',
  RemoveCorruptedSiteSearch = 'specs.siteSearch.RemoveCorruptedSiteSearch',
  ResponsiveSkin = 'specs.siteSearch.ResponsiveSearchBoxSkin',
  ShowMoreCollections = 'specs.siteSearch.showMoreCollections',
  useSearchHttpClient = 'specs.siteSearch.useSearchHttpClient',
  VespaSiteSearchEndpoints = 'specs.siteSearch.VespaSiteSearchEndpoints',
}
